import React, { useContext } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import Search from "@vahak/core-ui/dist/components/Search/Search";
import CurrentLocation from "../../../auth/current-location/CurrentLocation";

import { SelectOnChangeType } from "@vahak/core-ui/dist/components/Select";
import SearchProps from "@vahak/core-ui/dist/components/Search/Search.types";
import { generateCityLoadOption } from "../../../user-profile/vas-form/method";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types/location-search";
import { generateSelectOption } from "../../../../methods/uiUtil";
import { LocationSearchParamsOption } from "@vahak/core/dist/_services/city-search.service";
import { filteredArray, getJoinedStringFromArray } from "@vahak/core-ui/dist/methods/array";

export const mapPlaceSearchResponseIntoText = (place?: PlaceSearchResult) => {
    return getJoinedStringFromArray(filteredArray([place?.city, place?.state, place?.country], true).slice(0, 2));
};

type ChangeHandler = (value: PlaceSearchResult | undefined, valueText: string | undefined, fieldName: string) => void;

interface UserCurrentLocationProps {
    id?: string;
    showUserCurrentLocation?: boolean;
    name?: string;
    placeholder?: string;
    onChange?: ChangeHandler;
    value?: PlaceSearchResult;
    valueText?: string;
    autoFocus?: boolean;
    dropdownProps?: Pick<
        SearchProps,
        "menuPlacement" | "menuPosition" | "maxMenuHeight" | "menuIsOpen" | "isDisabled" | "required" | "label"
    >;
    locationSearchSource: LocationSearchParamsOption["source"];
    pointLocation?: boolean;
    showCompleteDesc?: boolean;
    tags?: LocationSearchParamsOption["tags"];
}

const UserCurrentLocation = ({
    id = "location",
    name = "location",
    showUserCurrentLocation = false,
    placeholder = "",
    onChange,
    value,
    valueText,
    autoFocus = false,
    dropdownProps,
    locationSearchSource: source,
    pointLocation = false,
    showCompleteDesc = false,
    tags
}: UserCurrentLocationProps) => {
    const { globalData } = useContext(AppContext);
    const userPlaceInfo = globalData?.["userPlaceInfo"] as undefined | PlaceSearchResult;
    const onClickGetCurrentLocation = () => {
        onChange?.(userPlaceInfo, mapPlaceSearchResponseIntoText(userPlaceInfo), name);
    };

    const SeparatorIndicator = () => <CurrentLocation id={id + "-current"} onClick={onClickGetCurrentLocation} />;

    const optionGeneratedFromPlaceInfo = !!value
        ? generateSelectOption(
              showCompleteDesc ? `${value?.description}` : mapPlaceSearchResponseIntoText(value),
              value,
              value?.place_id
          )
        : undefined;

    const optionGeneratedFromText = !!valueText ? generateSelectOption(valueText, valueText, valueText) : undefined;

    const currentSelectedOption = optionGeneratedFromPlaceInfo ?? optionGeneratedFromText;

    const handleChange: SelectOnChangeType = (value: any) => {
        onChange?.(
            value?.value,
            showCompleteDesc ? `${value?.value?.description}` : mapPlaceSearchResponseIntoText(value?.value),
            name
        );
    };

    return (
        <div>
            <Search
                name={name}
                onChange={handleChange}
                option={[]}
                loadOptions={(str, cb) =>
                    generateCityLoadOption(str, cb, pointLocation, {
                        source,
                        tags
                    })
                }
                customDropDownIcon={<></>}
                value={currentSelectedOption}
                placeholder={placeholder}
                {...(showUserCurrentLocation &&
                    !!userPlaceInfo &&
                    !(value || valueText) && {
                        customSeparatorIndicator: SeparatorIndicator
                    })}
                id={id}
                isClearable
                noOptionHandler={(o) => {
                    return o.inputValue ? "No such location" : "Enter a location";
                }}
                autoFocus={autoFocus}
                openMenuOnClick={false}
                {...dropdownProps}
            />
        </div>
    );
};

export default UserCurrentLocation;
