import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

// Components
const LorryPosting = dynamic(() => import("../LorryPosting"), {
    ssr: false
});
// Context
import { AppContext } from "@vahak/core/dist/app-context";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../helpers/constants";
import { EditLorryTypes, LORRY_CREATION_FORM_IDS } from "../../helpers/constants";
import { generataMaskUrlWithParams } from "../../../../methods/generataMaskUrlWithParams";
import { PostLorryResponse } from "../hooks";
import { NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../../../context/load-lorry-posting";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";

const ModalOrBottomSheetWrapper = dynamic(() => import("@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper"), {
    ssr: false
});

interface StandaloneLorryPostingFormModalProps {
    originationPage?: POSTING_FORM_MODAL_ORIGINATION_PAGE;
    onSuccessfulLorryCreation?: (val: PostLorryResponse) => void;
    KamClientSupplierCompanyId?: number;
    onModalClosing?: () => void;
    allowedLorryType?: typeof NEW_LORRY_TYPES[number];
    editLorryData?: EditLorryTypes;
}

const StandaloneLorryPostingFormModal = ({
    originationPage,
    onSuccessfulLorryCreation,
    KamClientSupplierCompanyId,
    onModalClosing,
    allowedLorryType,
    editLorryData
}: StandaloneLorryPostingFormModalProps) => {
    const { isCsDashboardUser } = useCsDashboard();
    const { clearPostingData } = useLoadLorryPosting();
    const router = useRouter();
    const { isPhoneVerified, companyId, mandateUserVerificationActionTriggered } = useContext(AppContext);

    const activeStep = router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME];

    const handleModalClose = () => {
        delete router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME];
        router.push(router, isCsDashboardUser ? undefined : generataMaskUrlWithParams(router.query), {
            shallow: true,
            scroll: false
        });
        clearPostingData();
        onModalClosing && onModalClosing();
    };

    useEffect(() => {
        /**
         * Base condition check
         */
        if (!router.asPath.includes(LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME) || editLorryData?.id) return;

        if (!mandateUserVerificationActionTriggered && document.referrer === "") {
            delete router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME];
            router.push(router, undefined, {
                shallow: true,
                scroll: false
            });
            return;
        } else {
            router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER;
            router.push(router, undefined, {
                shallow: true,
                scroll: false
            });
        }

        /* eslint-disable react-hooks/exhaustive-deps*/
    }, []);
    const dynamicWidth =
        activeStep === LORRY_CREATION_FORM_IDS.VERIFY_VEHICLE_FAILED ||
        activeStep === LORRY_CREATION_FORM_IDS.LORRY_POSTED_SUCCESSFULLY;

    if (!Boolean(isPhoneVerified && companyId)) {
        return <></>;
    }
    return (
        <>
            {router.asPath.includes(LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME) ? (
                <ModalOrBottomSheetWrapper
                    isOpen={router.asPath.includes(LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME)}
                    onToggle={handleModalClose}
                    showHeader={false}
                    paddedModal={false}
                    paddedSheet={false}
                    fullScreenSheet
                    modalSize={dynamicWidth ? "wide" : "fit"}
                    modalBodyHeight={"100vh"}
                >
                    {/* activeStep check is just for sanity, please let it be */}
                    {activeStep && (
                        <LorryPosting
                            activeStep={activeStep as LORRY_CREATION_FORM_IDS}
                            closeModal={handleModalClose}
                            originationPage={originationPage}
                            onSuccessfulLorryCreation={onSuccessfulLorryCreation}
                            KamClientSupplierCompanyId={KamClientSupplierCompanyId}
                            allowedLorryType={allowedLorryType}
                            editLorryData={editLorryData}
                        />
                    )}
                </ModalOrBottomSheetWrapper>
            ) : (
                <></>
            )}
        </>
    );
};

export default StandaloneLorryPostingFormModal;
