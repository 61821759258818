import React, { useContext } from "react";
import Styles from "./SuccessScreen.module.scss";

//Components
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Button from "@vahak/core-ui/dist/components/Button";

import CheckCircleGreen from "@vahak/core/dist/standard-icons/check-circle-green.svg";
import { GA4EventNames, IDs, MY_LOAD_LORRY_TABS, ROUTES } from "@vahak/core/dist/constants";

import LoadIcon from "@vahak/core/dist/icons/load_cube.svg";
import LorryIcon from "@vahak/core/dist/icons/lorry_mini.svg";
import { CompanyServiceType, useEventTrackerService } from "@vahak/core/dist/_services";
import { AppContext } from "@vahak/core/dist/app-context";
import { LOAD_POSTING_FORM_LOCATION_STATE } from "../../posting/load-posting/helpers/constants";
import { useRouter } from "next/router";
import { LOAD_CREATION_FORM_IDS, LORRY_CREATION_FORM_IDS } from "../../posting/helpers/constants";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../../posting/lorry-posting/helpers/constants";
import useValidateUserStatus from "../../../hooks/useValidateUserStatus";

interface SuccessScreenProps {}

const SuccessScreen = ({}: SuccessScreenProps) => {
    const { serviceType } = useContext(AppContext);

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const handleEventTracking = (name: string) => {
        const data = {
            source_screen: "onboarding_splash"
        };

        sendGAandMoEngageEvent({
            name,
            data
        });
    };

    const router = useRouter();

    /**
     * Load posting verification check
     */
    const { ValidateLoadPosting, ValidateLorryPosting } = useValidateUserStatus();

    const onClickPostLoad = () => {
        //Callback function
        delete router.query["referral_code"];
        router.query[LOAD_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LOAD_CREATION_FORM_IDS.LOAD_LOCATION_DETAILS;
        router.push(
            {
                ...router,
                pathname: ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING)
            },
            undefined,
            { shallow: true }
        );
        handleEventTracking(GA4EventNames.SPLASH_SCREEN["post_load_button_click"]);
    };

    const onClickPostLorry = () => {
        ValidateLorryPosting?.(() => {
            //Callback function
            delete router.query["referral_code"];
            router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER;
            router.push(
                {
                    ...router,
                    pathname: ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING)
                },
                undefined,
                { shallow: true }
            );
        });
        handleEventTracking(GA4EventNames.SPLASH_SCREEN["add_lorry_attach_lorry_btn"]);
    };

    const getInfoByServiceType = () => {
        switch (serviceType as CompanyServiceType) {
            case CompanyServiceType.TRANSPORT_CONTRACTOR:
                return "Post your first load or lorry to find the perfect match";

            case CompanyServiceType.FLEET_OWNER:
                return "Post your first lorry to find the perfect load";

            case CompanyServiceType.SHIPPER:
                return "Post your first load to find the perfect lorry";

            default:
                return "Post your first load or lorry to find the perfect match";
        }
    };

    return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" gap={10} className={Styles.main}>
            <CheckCircleGreen />
            <Typography color={COLORS.GREY_900} size="xl" weight="semibold">
                Signup success
            </Typography>
            <Typography color={COLORS.GREY_500} weight="semibold" align="center">
                {getInfoByServiceType()}
            </Typography>

            <Flex flexDirection="column" gap={20} className={Styles.btnWrapper}>
                {[CompanyServiceType.TRANSPORT_CONTRACTOR, CompanyServiceType.SHIPPER].includes(
                    serviceType as CompanyServiceType
                ) ? (
                    <Button type="button" isRounded blockBtn onClick={onClickPostLoad} id={IDs.auth.postLoad}>
                        <LoadIcon /> Post Load
                    </Button>
                ) : null}
                {[CompanyServiceType.TRANSPORT_CONTRACTOR, CompanyServiceType.FLEET_OWNER].includes(
                    serviceType as CompanyServiceType
                ) ? (
                    <Button type="button" isRounded blockBtn onClick={onClickPostLorry} id={IDs.auth.postLorry}>
                        <LorryIcon /> Post lorry
                    </Button>
                ) : null}
            </Flex>
        </Flex>
    );
};

export default SuccessScreen;
