import React, { useContext, useState } from "react";
import classnames from "classnames";

//Styles
import Styles from "./TopNavNew.module.scss";

//Icons
import VahakLogoBlue from "@vahak/core/dist/icons/VahakLogoBlue_n.svg";
import PlaystoreBadge from "@vahak/core/dist/icons/download_from_playstore.svg";
import PlaystoreBanner from "@vahak/core/dist/icons/download_from_playstore_banner.svg";
import LanguageIcon from "@vahak/core/dist/icons/language_icon.svg";
import AngleUpBlue from "@vahak/core/dist/standard-icons/up_arrow.svg";
import AngleDownBlue from "@vahak/core/dist/standard-icons/down_arrow.svg";
import CrossIcon from "@vahak/core/dist/icons/close-grey.svg";

//Components
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography/Typography";
import Button from "@vahak/core-ui/dist/components/Button/Button";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import ContinueSignup from "../continue-sign-up/ContinueSignup";
import { AppContext, ServiceRequestType } from "@vahak/core/dist/app-context";
import { useRouter } from "next/router";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import ProfileItemPopupMenu from "./profile-item-pop-menu/ProfileItemPopupMenu";
import { NAV_MENU_STRINGS } from "@vahak/core/dist/constants/languages";
import { IDs } from "@vahak/core/dist/constants/automation";
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import AppImg from "../../utils/AppImg";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import ServiceRequestModal from "../../service-request-modal/ServiceRequestModal";

interface TopNavNewProps {}

const TopNavNew = ({}: TopNavNewProps) => {
    const { companyId, isPhoneVerified, lang, setLang, serviceType, setServiceRequestType } = useContext(AppContext);

    const router = useRouter();

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const isDesktop = useMediaQuery({ queryType: "desktop" });
    const isMobile = useMediaQuery({ queryType: "mobile" });

    const renderLoginOrUserProfileButton = () => {
        if (isPhoneVerified) {
            return <ProfileItemPopupMenu />;
        } else
            return (
                <Button
                    size="s"
                    onClick={() => {
                        /**
                         * Events
                         */
                        sendGAandMoEngageEvent({
                            name: GA4EventNames.TOP_NAV.login_signup_clicked,
                            data: {
                                from_screen: router.asPath,
                                from: "top_nav"
                            }
                        });
                        router.push({ hash: "#Login" });
                    }}
                >
                    Login / Signup
                </Button>
            );
    };

    const handleClickMenu = (pathname: string, eventName?: string) => {
        /**
         * Events
         */
        sendGAandMoEngageEvent({
            name: eventName as string,
            data: {
                from_screen: router.asPath,
                from: "top_nav"
            }
        });

        resetMenuState();
        router.push(pathname);
    };

    const [toggleMenus, setToggleMenus] = useState<{
        isHamburgerMenuOpen: boolean;
        isLanguageMenuOpen: boolean;
        isDownloadAppModalOpen: boolean;
        menuDropDowns: {
            [key: string]: boolean;
        };
    }>({
        isHamburgerMenuOpen: false,
        isLanguageMenuOpen: false,
        isDownloadAppModalOpen: false,
        menuDropDowns: {
            [IDs.header.productsAndServices]: false
        }
    });

    const resetMenuState = () =>
        setToggleMenus({
            isHamburgerMenuOpen: false,
            isLanguageMenuOpen: false,
            isDownloadAppModalOpen: false,
            menuDropDowns: {
                [IDs.header.productsAndServices]: false
            }
        });

    const menuOptions = [
        {
            id: IDs.header.membership,
            route: ROUTES.membershipUpgrade.getUrl(),
            title: "Membership",
            eventName: GA4EventNames.TOP_NAV.manage_membership_clicked,
            isDropDown: false
        },
        {
            id: IDs.header.marketplace,
            route: ROUTES.marketplace.getUrl("load"),
            title: "Marketplace",
            eventName: GA4EventNames.TOP_NAV.marketplace_icon_clicked,
            isDropDown: false
        },
        {
            id: IDs.header.transportDirectory,
            route: ROUTES.transporters.getUrl(),
            title: "Transport Directory",
            eventName: GA4EventNames.TOP_NAV.transport_directory_clicked,
            isDropDown: false
        },
        {
            id: IDs.header.productsAndServices,
            route: "",
            title: "Products/Services",
            eventName: GA4EventNames.TOP_NAV.transport_directory_clicked,
            isDropDown: true,
            openOnHover: true,
            subMenus: [
                {
                    id: IDs.header.productsAndServicesSubMenu.gps,
                    route: "",
                    title: "GPS",
                    onClick: () => setServiceRequestType?.(ServiceRequestType.GPS)
                    // eventName: GA4EventNames.TOP_NAV.transport_directory_clicked
                }
                // {
                //     id: IDs.header.productsAndServicesSubMenu.cargoInsurance,
                //     route: "",
                //     title: "Cargo Insurance",
                //     onClick: () => setServiceRequestType?.(ServiceRequestType.CARGO_INSURANCE)
                //     // eventName: GA4EventNames.TOP_NAV.transport_directory_clicked
                // }
                // {
                //     id: IDs.header.productsAndServicesSubMenu.vehicleInsurance,
                //     route: "",
                //     title: "Vehicle Insurance",
                //     onClick: () => setServiceRequestType?.(ServiceRequestType.VEHICLE_INSURANCE)
                //     // eventName: GA4EventNames.TOP_NAV.transport_directory_clicked
                // },
                // {
                //     id: IDs.header.productsAndServicesSubMenu.businessLoan,
                //     route: "",
                //     title: "Business Loan",
                //     onClick: () => setServiceRequestType?.(ServiceRequestType.BUSINESS_LOAN)
                //     // eventName: GA4EventNames.TOP_NAV.transport_directory_clicked
                // }
            ]
        }
    ];

    const PageLanguages = NAV_MENU_STRINGS.find((item) => router.asPath.split("?")[0].includes(item.menu));

    const renderLangList = () => {
        return (
            <ul className={classnames(Styles.languageSubmenuWrapper)}>
                {PageLanguages?.languages.map((language) => {
                    return (
                        <li
                            className={classnames(Styles.languageSubmenu, (lang || 0) === language.id && Styles.active)}
                            key={language.id}
                            onClick={() => {
                                setLang?.(language.id);
                                resetMenuState();
                            }}
                        >
                            <Typography weight="medium" size="sm">
                                {language.labelInLanguage}
                            </Typography>
                            <Typography weight="medium" size={language.labelInEn === "English" ? "sm" : "xs"}>
                                {language.labelInEn}
                            </Typography>
                            <span className={Styles.selectDot}></span>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const handleClickDownload = () => {
        /**
         * Events
         */
        sendGAandMoEngageEvent({
            name: GA4EventNames.TOP_NAV.download_app_clicked_web,
            data: {
                from_screen: router.asPath,
                from: "top_nav"
            }
        });

        isMobile
            ? window.open("https://vahak.page.link/YyZk", "_blank")
            : setToggleMenus((prevState) => ({
                  ...prevState,
                  isDownloadAppModalOpen: !prevState.isDownloadAppModalOpen
              }));
    };

    return (
        <main className={Styles.main}>
            <ContinueSignup />
            <Flex
                className={classnames(Styles.mainWrapper, "container")}
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex alignItems="center" gap={10}>
                    {!isDesktop ? (
                        <div
                            className={classnames(
                                Styles.hamburgerMenu,
                                toggleMenus.isHamburgerMenuOpen ? Styles.open : ""
                            )}
                            onClick={() => {
                                setToggleMenus({
                                    isDownloadAppModalOpen: false,
                                    isLanguageMenuOpen: false,
                                    isHamburgerMenuOpen: !toggleMenus.isHamburgerMenuOpen,
                                    menuDropDowns: {
                                        [IDs.header.productsAndServices]: false
                                    }
                                });
                            }}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className={Styles.logoWrapper}
                        id={IDs.common.vahakLogo}
                        onClick={() => {
                            if (companyId) {
                                if (router?.pathname?.includes("/dashboard")) return;
                                router.push(ROUTES.dashboard.getUrl());
                            } else {
                                if (router?.pathname?.includes("/marketplace")) return;
                                if (serviceType == CompanyServiceType.SHIPPER) {
                                    router.push(ROUTES.marketplace.getUrl("lorry"));
                                } else {
                                    router.push(ROUTES.marketplace.getUrl("load"));
                                }
                            }
                        }}
                    >
                        <VahakLogoBlue />
                    </div>
                </Flex>
                {isDesktop ? (
                    <Flex gap={40} alignItems="center" className={Styles.menuContainer}>
                        {menuOptions.map((menu) => {
                            if (menu.isDropDown) {
                                const { subMenus, openOnHover } = menu;
                                return (
                                    <Dropdown
                                        isOpen={toggleMenus.menuDropDowns[menu.id]}
                                        DropdownToggleBtn={
                                            <div
                                                className={classnames(
                                                    Styles.dropdownToggleClassName,
                                                    toggleMenus.menuDropDowns[menu.id] && Styles.active
                                                )}
                                            >
                                                <Button
                                                    fillType="text"
                                                    onClick={() => {}}
                                                    id={menu.id}
                                                    onMouseEnter={() =>
                                                        openOnHover
                                                            ? setToggleMenus({
                                                                  ...toggleMenus,
                                                                  menuDropDowns: {
                                                                      [menu.id]: true
                                                                  }
                                                              })
                                                            : undefined
                                                    }
                                                >
                                                    <Typography
                                                        color={
                                                            toggleMenus.menuDropDowns[menu.id]
                                                                ? COLORS.BLUE
                                                                : COLORS.BLACK
                                                        }
                                                        weight="semibold"
                                                    >
                                                        {menu.title}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        }
                                        handleToggle={() => {
                                            setToggleMenus({
                                                ...toggleMenus,
                                                menuDropDowns: {
                                                    [menu.id]: !toggleMenus.menuDropDowns[menu.id]
                                                }
                                            });
                                        }}
                                        dropdownClassName={Styles.languageDropdown}
                                        dropdownToggleClassName={Styles.languageDropdownToggle}
                                    >
                                        <Flex
                                            flexDirection="column"
                                            padding={16}
                                            gap={20}
                                            className={Styles.dropdownMenuList}
                                        >
                                            {subMenus?.map((subMenu) => {
                                                const { onClick } = subMenu;
                                                return (
                                                    <div className={Styles.subMenuItems}>
                                                        <Button
                                                            fillType="text"
                                                            onClick={() => {
                                                                setToggleMenus({
                                                                    ...toggleMenus,
                                                                    menuDropDowns: {
                                                                        [menu.id]: false
                                                                    }
                                                                });
                                                                onClick
                                                                    ? onClick()
                                                                    : handleClickMenu(subMenu.route, menu.eventName);
                                                            }}
                                                            id={subMenu.id}
                                                        >
                                                            <Typography color={COLORS.GREY_500} weight="semibold">
                                                                {subMenu.title}
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                );
                                            })}
                                        </Flex>
                                    </Dropdown>
                                );
                            } else
                                return (
                                    <Button
                                        fillType="text"
                                        onClick={() => {
                                            handleClickMenu(menu.route, menu.eventName);
                                        }}
                                        id={menu.id}
                                    >
                                        <Typography color={COLORS.BLACK} weight="semibold">
                                            {menu.title}
                                        </Typography>
                                    </Button>
                                );
                        })}
                    </Flex>
                ) : (
                    <></>
                )}

                <Flex alignItems="center" gap={15}>
                    {isDesktop && PageLanguages ? (
                        <Dropdown
                            isOpen={toggleMenus.isLanguageMenuOpen}
                            DropdownToggleBtn={
                                <div
                                    className={Styles.langIconWrapper}
                                    style={{ cursor: "pointer" }}
                                    // onMouseOver={openLanguageMenu}
                                >
                                    <LanguageIcon />
                                </div>
                            }
                            handleToggle={() => {
                                setToggleMenus({
                                    ...toggleMenus,
                                    isLanguageMenuOpen: !toggleMenus.isLanguageMenuOpen
                                });
                            }}
                            dropdownClassName={Styles.languageDropdown}
                            dropdownToggleClassName={Styles.languageDropdownToggle}
                        >
                            {renderLangList()}
                        </Dropdown>
                    ) : (
                        <></>
                    )}
                    {isDesktop ? (
                        <div onClick={handleClickDownload}>
                            <PlaystoreBadge style={{ height: "100%" }} />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className={Styles.loginBtn} id={IDs.profileDropDown.profileDropdownBtn}>
                        {renderLoginOrUserProfileButton()}
                    </div>
                </Flex>
            </Flex>

            {/* App draw */}
            {isMobile ? (
                <div className={classnames(Styles.appDrawMenu, toggleMenus.isHamburgerMenuOpen ? Styles.isOpen : "")}>
                    <Flex flexDirection="column" className={Styles.appDrawMenuOptionListWrapper}>
                        {menuOptions.map((menu) => {
                            if (menu.isDropDown) {
                                const { subMenus } = menu;
                                return (
                                    <div
                                        className={classnames(
                                            Styles.appDrawMenuOption,
                                            Styles.subMenuOption,
                                            toggleMenus.menuDropDowns[menu.id] && Styles.isOpen
                                        )}
                                    >
                                        <Button
                                            fillType="text"
                                            onClick={() => {
                                                setToggleMenus({
                                                    ...toggleMenus,
                                                    menuDropDowns: {
                                                        [menu.id]: !toggleMenus.menuDropDowns[menu.id]
                                                    }
                                                });
                                            }}
                                            id={menu.id}
                                        >
                                            <Typography color={COLORS.BLACK} weight="semibold" mSize="sm">
                                                {menu.title}{" "}
                                                {toggleMenus.menuDropDowns[menu.id] ? (
                                                    <AngleUpBlue />
                                                ) : (
                                                    <AngleDownBlue />
                                                )}
                                            </Typography>
                                        </Button>

                                        {toggleMenus.menuDropDowns[menu.id] ? (
                                            <ul className={classnames(Styles.subMenuWrapper)}>
                                                {subMenus?.map((sub) => {
                                                    const { onClick } = sub;
                                                    return (
                                                        <li
                                                            className={classnames(Styles.subMenu)}
                                                            onClick={() => {
                                                                onClick
                                                                    ? onClick()
                                                                    : handleClickMenu(sub.route, menu.eventName);
                                                            }}
                                                        >
                                                            <Typography
                                                                mSize="sm"
                                                                weight="medium"
                                                                color={COLORS.GREY_700}
                                                            >
                                                                {sub.title}
                                                            </Typography>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={Styles.appDrawMenuOption}>
                                        <Button
                                            fillType="text"
                                            onClick={() => {
                                                handleClickMenu(menu.route, menu.eventName);
                                            }}
                                            id={menu.id}
                                        >
                                            <Typography color={COLORS.BLACK} weight="semibold" mSize="sm">
                                                {menu.title}
                                            </Typography>
                                        </Button>
                                    </div>
                                );
                            }
                        })}
                        {PageLanguages ? (
                            <div className={classnames(Styles.appDrawMenuOption, Styles.langDropDown)}>
                                <Button
                                    fillType="text"
                                    onClick={() => {
                                        setToggleMenus({
                                            ...toggleMenus,
                                            isLanguageMenuOpen: !toggleMenus.isLanguageMenuOpen
                                        });
                                    }}
                                >
                                    <Typography color={COLORS.BLACK} weight="semibold" mSize="sm">
                                        Change Language{" "}
                                        {toggleMenus.isLanguageMenuOpen ? <AngleUpBlue /> : <AngleDownBlue />}
                                    </Typography>
                                </Button>
                                {toggleMenus.isLanguageMenuOpen ? renderLangList() : ""}
                            </div>
                        ) : (
                            <></>
                        )}
                        {toggleMenus.isLanguageMenuOpen || toggleMenus.menuDropDowns[IDs.header.productsAndServices] ? (
                            <></>
                        ) : (
                            <div className={classnames(Styles.downloadBanner)} onClick={handleClickDownload}>
                                <PlaystoreBanner />
                            </div>
                        )}
                    </Flex>
                </div>
            ) : (
                <></>
            )}

            {/* Download app custom modal */}
            {toggleMenus.isDownloadAppModalOpen ? (
                <div id="overlay">
                    <div className="downloadAppQrCode">
                        <div className="wrapper">
                            <div
                                className="closeBtn"
                                onClick={() =>
                                    setToggleMenus(() => ({
                                        ...toggleMenus,
                                        isDownloadAppModalOpen: false
                                    }))
                                }
                            >
                                <CrossIcon />
                            </div>
                            <Flex
                                className="QrCode"
                                flexDirection="column"
                                alignItems="center"
                                style={{ paddingTop: "20px" }}
                            >
                                <div className="title">
                                    <Typography size="md" color={COLORS.GREY_900} weight="semibold">
                                        Scan QR code to download app
                                    </Typography>
                                </div>

                                <AppImg src={"qr_download_link.png"} width={220} height={220} />
                            </Flex>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </main>
    );
};

export default TopNavNew;
