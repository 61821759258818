import { SelectOption } from "@vahak/core-ui/dist/components/Select/Select.types";
import { LocationSearchParamsOption, searchUsingPlacesAPI } from "@vahak/core/dist/_services/city-search.service";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types/index";
import { generateSelectOption } from "../../../methods/uiUtil";

export const generateCityLoadOption = async (
    str: string,
    callback: Function,
    pointLocation = false,
    option: LocationSearchParamsOption
) => {
    if (str && str.length >= 2) {
        const results = await searchUsingPlacesAPI(str, {
            tags: pointLocation ? ["point_locations"] : [],
            ...option
        });
        if (results && results.length) {
            const option = results?.map(
                (res: PlaceSearchResult): SelectOption<PlaceSearchResult> =>
                    generateSelectOption(res.description ?? "", res, res.place_id)
            );
            callback(option);
            return;
        }
    }

    callback([]);
};

export const generateCityWithPointTagLoadOption = async (
    str: string,
    callback: Function,
    option: LocationSearchParamsOption
) => {
    generateCityLoadOption(str, callback, true, option);
};
