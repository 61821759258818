import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";

//Style
import Styles from "./ContinueSignup.module.scss";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";

// Icons
import AngleRightOrange from "@vahak/core/dist/icons/AngelRightOrange.svg";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { AppContext } from "@vahak/core/dist/app-context";

const ContinueSignup = () => {
    const router = useRouter();
    const { isPhoneVerified, companyId } = useContext(AppContext);
    const [hideSignUpBanner, setHideSignUpBanner] = useState(true);

    const handleClick = () => {
        router.push({ hash: "#Login" });
    };

    useEffect(() => {
        if (!isPhoneVerified || companyId) {
            setHideSignUpBanner(true);
        } else {
            setHideSignUpBanner(false);
        }
    }, [isPhoneVerified, companyId]);

    if (hideSignUpBanner) return <></>;
    return (
        <div className={classnames(Styles.main)}>
            <div className={classnames(Styles.wrapper, "container")}>
                <Typography size="m" mSize="s" as="p" align="center">
                    Complete the company registration
                </Typography>
                <div className={Styles.actionBtn} onClick={handleClick}>
                    <Typography size="m" mSize="s" as="p" color={COLORS.ORANGE_500} underline>
                        Continue SignUp <AngleRightOrange />
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ContinueSignup;
